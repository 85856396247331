.topnav {
  background-color: #222222;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 0.5em;
  color: white;
}

.app-name {
  padding: 14px 16px;
  font-size: 24px;
}

.header-user-name {
  padding: 14px 18px;
}

.header-login {
  padding: 14px 18px;
}

.header-login:hover {
  cursor: pointer;
  text-decoration: underline;
}
