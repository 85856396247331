#root {
  background-color: rgba(225, 223, 221, 0.2);
  font-family: Arial, Helvetica, sans-serif;
}

html,
body {
  margin: 0;
  height: 100%;
}

.home {
  padding: 10px;
}

.content {
  width: 100%;
}

/*
To use Flexbox we need to set the display
setting of the container component to flex.
The we set the direction of the wrapped 
components. By default flexbox will order 
them by row, but we can also tell it to do
it by column.
*/
.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}
/*
Something similar is done to the body 
element, except we order the wrapped 
component as rows.
*/
.App .body {
  display: flex;
  flex-direction: row;
  flex: 1;
}
/*
Flexbox let's us reorder the way the 
elements are placed inside the wrapper
component. Using the order rule, we move
the sidebar to the left of the content 
component. We also set its width to 12em
and set its flex propertires so it doesn't
grow or shrink with the rest of the page.
*/
.body .sidebar {
  order: -1;
  max-width: 12em;
  flex-grow: 0; /* Defined the ability to grow */
  flex-shrink: 0; /* Defines the ability to shrink */
  flex-basis: 12em; /* Default value of the element before distribuing the remaing space */
}
/*
Lastly we give some custom height to our 
header and footer components.
*/
.App .header,
.App .footer {
  height: 4em;
}

.body .content .main {
  padding-left: 1em;
}

.main {
  width: 100vw;
  overflow: hidden;
}

.Button {
  justify-content: center;
  background-color: #00a1f1;
  border: none;
  color: white;
  display: inline-block;
  padding: 12px 18px;
  margin: 10px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 4px;
}

.center {
  margin-top: 50px;
  text-align: center;
}

.filter-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 20px 10px 0px;
}

.textInputContainer {
  margin: 10px 0px;
}

.inlineflex .ms-ChoiceField {
  display: inline-block;
}

.inlineflex {
  margin-top: 15px;
  display: flex;
}

.ms-ChoiceField-wrapper {
  margin: 0px 10px 15px 0px;
}

.choiceGroup {
  display: flex;
  justify-content: space-between;
}

.radioButton {
  display: flex;
}

.choiceLabel {
  margin-right: 20px;
}

.page-title {
  margin-left: 25px;
}

.link {
  text-decoration: none;
}

/* 
Doing this because the @fluentui/react-charting Donut component renders the labels
for the chart in a <strong> component, which we want to over-ride to match the 
font-weight of the .ms-Label class
 */
.ms-Label > div > strong:first-of-type {
  font-weight: 600 !important;
}

/* This makes it so the empty layer created by Panel/Dialog components don't shift elements around */
span.ms-layer:empty {
  display: none;
}

.user-announcement {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: gray;
  border: 2px solid gray;
  color: black;
}

.user-announcement .icon {
  margin-right: 10px;
}

.buttonDisabled {
  color: #fff4ce !important;
  background-color: #fff4ce !important;
}

.buttonEnabled {
  color: black !important;
  background-color: #fff4ce !important;
}

.buttonEnabled:hover {
  background-color: white;
}
